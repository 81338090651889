import React from "react";

export default function Skills() {

  function calculateAge(birthdate) {
    const today = new Date();
    const birthDate = new Date(birthdate);
    
    // Calculate the difference in years
    let age = today.getFullYear() - birthDate.getFullYear();
  
    // Check if the birthday hasn't occurred yet this year
    const todayMonth = today.getMonth();
    const birthMonth = birthDate.getMonth();
    if (todayMonth < birthMonth || (todayMonth === birthMonth && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  }
  const birthdate = '1999-04-21'; // Your birthdate in yyyy-mm-dd format
  const age = calculateAge(birthdate);

    const skills = [
        {
            title: "HTML5",
            progress: 95
        },
        {
            title: "CSS3",
            progress: 95
        },
        {
            title: "Javascript",
            progress: 90
        },
        {
            title: "React.js",
            progress: 80
        },
        {
            title: "Next.js",
            progress: 75
        },
        {
            title: "Python",
            progress: 75
        },
        {
            title: "Java",
            progress: 65
        },
        {
            title: ".NET",
            progress: 60
        },
        {
            title: "Microsoft office",
            progress: 90
        },
        {
            title: "Server Management",
            progress: 85
        },
        {
            title: "Bash",
            progress: 80
        },
        {
            title: "Mongodb",
            progress: 85
        },
        {
            title: "Express",
            progress: 80
        },
        {
            title: "Git",
            progress: 95
        },
        {
            title: "C++",
            progress: 50
        },
        {
            title: "Power BI",
            progress: 70
        },
        {
            title: "Welding",
            progress: 40
        },
        {
            title: "electrical engineering",
            progress: 75
        },
        {
            title: "Mathematics",
            progress: 65
        },
        {
            title: "SQL",
            progress: 85
        },
        {
            title: "Business management",
            progress: 85
        },
        {
            title: "Emotional awareness",
            progress: 95
        },
        {
            title: "Problem solving",
            progress: 95
        },
        {
            title: "bpmn scheme",
            progress: 75
        },
        {
            title: "case studies",
            progress: 70
        },
        {
            title: "Out of the box thinking",
            progress: 100
        },
        {
            title: "finding patterns",
            progress: 90
        },
        {
            title: "efficient pathfinding",
            progress: 95
        },
        {
            title: "Yachting",
            progress: 70
        },
        {
            title: "Skydive",
            progress: 50
        },
        {
            title: "Ice skating",
            progress: 50
        },
        {
            title: "design thinking",
            progress: 70
        },
        {
            title: "devops",
            progress: 80
        },
        {
            title: "Github",
            progress: 80
        },
        {
            title: "Domotica (raspberry pi & arduino)",
            progress: 80
        },
        {
            title: "gaming",
            progress: 85
        },
        {
            title: "gym/fitness",
            progress: 80
        },
        {
            title: "food/diet",
            progress: 60
        },
        {
            title: "network ict",
            progress: 60
        },
        {
            title: "brain patterns",
            progress: 90
        },
    ]

  return (
    <>
      <main>
        <section className="container about">
          <div className="main-title">
              <h2>My <span>skills</span><span className="bg-text">About me</span></h2>
          </div>
          <div className="about-container">
              <div className="left-about">
                  <h4>Information About me</h4>
                  <p>
                    Since childhood, I've been passionate about building and creating. 
                    My mission now is clear: master every facet of life and inspire others to follow their dreams with boundless energy. 
                    As they say, 'Energy fuels growth.
                    <br /> <br /> 
                    I'm committed to showing people a fearless path towards their aspirations. 
                    Embrace your dreams without hesitation. 
                    My journey is about demonstrating that with unwavering determination, anyone can transform their life.
                    <br /> <br />
                    My life motto &rarr; MASTER ECLIPSE, EGNITE REALMS, HONOR TRIUMPHS
                  </p>
                  {/* <div className="btn-con">
                      <a href="#" className="main-btn">
                          <span className="btn-text">Download CV</span>
                          <span className="btn-icon"><i className="fas fa-download"></i></span>
                      </a>
                  </div> */}
              </div>
              <div className="right-about">
                  <div className="about-item">
                      <div className="abt-text">
                          <p className="large-text">{age}</p>
                          <p className="small-text">Years <br /> Old</p>
                      </div>
                  </div>
                  <div className="about-item">
                      <div className="abt-text">
                          <p className="large-text">5+</p>
                          <p className="small-text">Years of <br /> experience</p>
                      </div>
                  </div>
                  <div className="about-item">
                      <div className="abt-text">
                          <p className="large-text">50+</p>
                          <p className="small-text">Happy <br /> Clients</p>
                      </div>
                  </div>
                  <div className="about-item">
                      <div className="abt-text">
                          <p className="large-text">10+</p>
                          <p className="small-text">Fields <br /> Mastered</p>
                      </div>
                  </div>
              </div>
          </div>
          <div className="about-stats">
              <h4 className="stat-title">Certs/Hobbies</h4>
              <div className="progress-bars">
                  <div className="progress-bar">
                      <p className="prog-title">Skydive 🪂</p>
                      <div className="progress-con">
                          <p className="prog-text">AFF level 6</p>
                      </div>
                  </div>
                  <div className="progress-bar">
                      <p className="prog-title">Diving 🤿</p>
                      <div className="progress-con">
                          <p className="prog-text">1*</p>
                      </div>
                  </div>
                  <div className="progress-bar">
                      <p className="prog-title">Sport Shooting 🔫</p>
                      <div className="progress-con">
                          <p className="prog-text">In Progress...</p>
                      </div>
                  </div>
                  <div className="progress-bar">
                      <p className="prog-title">Guitar 🎶</p>
                      <div className="progress-con">
                          <p className="prog-text">few songs 😅</p>
                      </div>
                  </div>
                  <div className="progress-bar">
                      <p className="prog-title">Sailing ⛵️</p>
                      <div className="progress-con">
                          <p className="prog-text">Yachtman (sail & motor) certificate + VHF certificate</p>
                      </div>
                  </div>
                  <div className="progress-bar">
                      <p className="prog-title">Driver licenses 🛞</p>
                      <div className="progress-con">
                          <p className="prog-text">AM - A1 - A2 - A - B</p>
                      </div>
                  </div>
              </div>
            </div>
            <div className="about-stats">
                <h4 className="stat-title">My Skills</h4>
                <div className="progress-bars">
                {skills.map((skill) => (
                    <div className="progress-bar">
                        <p className="prog-title">{skill.title}</p>
                        <div className="progress-con">
                            <p className="prog-text">{skill.progress}%</p>
                            <div className="progress">
                                <span style={{ width: `${skill.progress}%` }}></span>
                            </div>
                        </div>
                    </div>
                ))}

                    

              </div>
          </div>
          <h4 className="stat-title">My Timeline</h4>
          <div className="timeline">
            <div className="timeline-item">
              <div className="tl-icon">
                  <i className="fas fa-briefcase"></i>
              </div>
              <p className="tl-duration">2023 - present</p>
              <h5>Otlon<span> - Luxury brand Company</span></h5>
              <p>
                Elevating style, enhancing well-being through exquisite fashion.
              </p>
            </div>
            <div className="timeline-item">
              <div className="tl-icon">
                <i className="fas fa-briefcase"></i>
              </div>
              <p className="tl-duration">2023 - present</p>
              <h5>Wantestate<span> - Real Estate Company</span></h5>
              <p>
                Having a nice home fosters great ideas and well-being.
              </p>
            </div>
            <div className="timeline-item">
              <div className="tl-icon">
                  <i className="fas fa-briefcase"></i>
              </div>
              <p className="tl-duration">2022 - present</p>
              <h5>Bachelor Applied Computer science: Artificial Intelligence<span> - Vives Kortrijk</span></h5>
              <p>
                This course will help me take customer support to the next level.
              </p>
            </div>
            <div className="timeline-item">
              <div className="tl-icon">
                  <i className="fas fa-briefcase"></i>
              </div>
              <p className="tl-duration">2021 - present</p>
              <h5>Onplo<span> - Software Company</span></h5>
              <p>
                We develop software solutions, building the digital world.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
