import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const CompanyImageBlock = styled.div`
  width: 100%;
  height: 100%;
  /* border: 1px solid var(--color-secondary); */
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const CompanyImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const HireImage = styled(CompanyImage)`
  border-radius: 20px;
`;

const TextItem = styled.div`
  position: relative;
  width: 100%;
  min-height: 30px;
  /* margin: 5px 10px; */
  padding: 5px 20px;
  font-weight: bold;
`;

export default function Works() {

  const [isLightMode, setIsLightMode] = useState(false);

  useEffect(() => {
    // Function to check for the presence of .light-mode globally
    const checkLightMode = () => {
      const isLightModeActive = document.body.classList.contains('light-mode');
      setIsLightMode(isLightModeActive);
    };

    // Initial check
    checkLightMode();

    // Set up an interval to periodically check for .light-mode (adjust the interval as needed)
    const interval = setInterval(checkLightMode, 250);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <main>
        {/* <section className="container">
          <div className="main-title">
              <h2><span>Hire</span> me<span className="bg-text">My Work</span></h2>
          </div>
          <p className="port-text">
            Choose the service you would like.
          </p>
          <div className="portfolios">
            <div className="portfolio-item">
              <CompanyImageBlock className="image">
                <HireImage src="img/sailing.jpg" alt="" />
                <TextItem>
                  Captain sailing/powerboat (Yachtman cert*)
                </TextItem>
              </CompanyImageBlock>
              <div className="hover-items">
                <h3>Contact me</h3>
                <div className="icons">
                  <Link to="/contact" className="icon">
                    <i className="fa-solid fa-envelope"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="portfolio-item">
              <CompanyImageBlock className="image">
                <HireImage src="img/coaching.jpg" alt="" />
                <TextItem>
                  life/business coaching
                </TextItem>
              </CompanyImageBlock>
              <div className="hover-items">
                <h3>Contact me</h3>
                <div className="icons">
                  <Link to="/contact" className="icon">
                    <i className="fa-solid fa-envelope"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="container">
          <div className="main-title">
              <h2>My <span>Companies</span><span className="bg-text">My Work</span></h2>
          </div>
          <p className="port-text">
            Here are companies improving the world.
          </p>
          <div className="portfolios">
            <div className="portfolio-item">
              <CompanyImageBlock className="image">
                <CompanyImage src="img/logo_onplo.png" alt="" />
              </CompanyImageBlock>
              <div className="hover-items">
                  <h3>Digital World</h3>
                  <div className="icons">
                    <a href="https://onplo.com" target="_blank" rel="noreferrer" className="icon">
                      <i className="fa-solid fa-globe"></i>
                    </a>
                    <a href="https://www.instagram.com/officialonplo/" target="_blank" rel="noreferrer" className="icon">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="mailto:contact@onplo.com" className="icon">
                      <i className="fa-solid fa-envelope"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="portfolio-item">
                <CompanyImageBlock className="image">
                  {isLightMode ? (
                    <CompanyImage src="img/Otlon_logo_black.png" alt="" />
                  ) : (
                    <CompanyImage src="img/Otlon_logo_white.png" alt="" />
                  )}
                </CompanyImageBlock>
                <div className="hover-items">
                  <h3>Quality Brand</h3>
                  <div className="icons">
                    <a href="https://otlon.com" target="_blank" rel="noreferrer" className="icon">
                      <i className="fa-solid fa-globe"></i>
                    </a>
                    <a href="https://www.instagram.com/otlonofficial/" target="_blank" rel="noreferrer" className="icon">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="mailto:contact@otlon.com" className="icon">
                      <i className="fa-solid fa-envelope"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="portfolio-item">
                <CompanyImageBlock className="image">
                    <CompanyImage src="img/logo_ozivon.png" alt="" />
                </CompanyImageBlock>
                <div className="hover-items">
                  <h3>Real Estate</h3>
                  <div className="icons">
                    <a href="https://ozivon.com" target="_blank" rel="noreferrer" className="icon">
                      <i className="fa-solid fa-globe"></i>
                    </a>
                    <a href="https://www.instagram.com/ozivon_official/" target="_blank" rel="noreferrer" className="icon">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="mailto:contact@ozivon.com" className="icon">
                      <i className="fa-solid fa-envelope"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="portfolio-item">
                <CompanyImageBlock className="image">
                    <CompanyImage src="img/logo_oymor.png" alt="" />
                </CompanyImageBlock>
                <div className="hover-items">
                  <h3>Coaching</h3>
                  <div className="icons">
                    <a href="https://oymor.com" target="_blank" rel="noreferrer" className="icon">
                      <i className="fa-solid fa-globe"></i>
                    </a>
                    <a href="https://www.instagram.com/oymor_official/" target="_blank" rel="noreferrer" className="icon">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="mailto:contact@oymor.com" className="icon">
                      <i className="fa-solid fa-envelope"></i>
                    </a>
                  </div>
                </div>
              </div>
          </div>
        </section>
      </main>
    </>
  )
}
